import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useDataProvider, useTranslate, useNotify, useRefresh, } from 'react-admin';
import AllOrganizations from './AllOrganization';
import YourDashboard from './YourDashborad';
import PaymentRecord from './PaymenrRecord';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { acl } from '../utils/dummyData';
import { Grid, useMediaQuery } from '@mui/material';
var Dashboard = function (props) {
    var translate = useTranslate();
    var notify = useNotify();
    var refresh = useRefresh();
    var _a = useState(acl), acessControlList = _a[0], setAcessControlList = _a[1];
    var _b = useState(""), userOrg = _b[0], setUserOrg = _b[1];
    var dataProvider = useDataProvider();
    useEffect(function () {
        var _a;
        try {
            var cachedAuth = (_a = JSON.parse(localStorage.getItem('auth'))) !== null && _a !== void 0 ? _a : {};
            if (cachedAuth.id) {
                dataProvider.getOneWithoutDataJSON("users", { id: cachedAuth.id })
                    .then(function (data) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                    var newACList = ((_a = data === null || data === void 0 ? void 0 : data.profile) === null || _a === void 0 ? void 0 : _a.ACL) || {};
                    setAcessControlList(newACList);
                    localStorage.setItem("userACL", JSON.stringify(newACList));
                    setUserOrg((_e = (_d = (_c = (_b = data.profile) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c['current_organization']) === null || _d === void 0 ? void 0 : _d[1]) !== null && _e !== void 0 ? _e : "N/A");
                    localStorage.setItem("appBarOrg", (_j = (_h = (_g = (_f = data.profile) === null || _f === void 0 ? void 0 : _f.info) === null || _g === void 0 ? void 0 : _g['current_organization']) === null || _h === void 0 ? void 0 : _h[1]) !== null && _j !== void 0 ? _j : "N/A");
                    refresh();
                })
                    .catch(function (error) {
                    console.error(error);
                    localStorage.setItem("appBarOrg", "N/A");
                    localStorage.setItem("userOrgId", "N/A");
                    notify(translate('error.ACL.getACLFailed'), { type: 'error' });
                });
                dataProvider.ShowUrOrg({ id: cachedAuth.id })
                    .then(function (data) {
                    setUserOrg(data.data.profile.info.current_organization[1]);
                })
                    .catch(function (error) {
                    console.error(error);
                    notify(translate('error.organization.getUserOrgFailed'), { type: 'error' });
                });
            }
        }
        catch (error) {
            localStorage.setItem("appBarOrg", "N/A");
            localStorage.setItem("userOrgId", "N/A");
        }
    }, []);
    var _c = useState(0), selectedTab = _c[0], setSelectedTab = _c[1];
    var isMobileScreen = useMediaQuery("(max-width: 750px)");
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { px: isMobileScreen ? 1 : 2, sx: {
                        overflowX: 'hidden',
                        width: '100%'
                    }, children: acessControlList.dashboard_admin.view ? (_jsxs(Tabs, { variant: "scrollable", scrollButtons: "auto", value: selectedTab, onChange: function (event, newValue) { return setSelectedTab(newValue); }, sx: {
                            backgroundColor: "#1561a5",
                            borderRadius: '8px',
                            marginTop: '1.3rem',
                            width: '100%',
                            minHeight: isMobileScreen ? '48px' : '64px',
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'nowrap',
                            },
                            '& .MuiTabs-scroller': {
                                overflow: 'auto !important',
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': {
                                    display: 'none'
                                }
                            }
                        }, TabIndicatorProps: {
                            style: {
                                backgroundColor: '#03a9f4',
                                height: 6,
                                borderRadius: '1.5px',
                            },
                        }, children: [_jsx(Tab, { label: translate('pages.dashboard.title.allOrganizations'), sx: {
                                    color: 'white',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    minHeight: isMobileScreen ? '48px' : '64px',
                                    fontSize: isMobileScreen ? '0.875rem' : '1rem',
                                    '&.Mui-selected': {
                                        color: 'white',
                                    },
                                } }), _jsx(Tab, { label: "".concat(translate('pages.dashboard.title.yourOrganization')), sx: {
                                    color: 'white',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    minHeight: isMobileScreen ? '48px' : '64px',
                                    fontSize: isMobileScreen ? '0.875rem' : '1rem',
                                    '&.Mui-selected': {
                                        color: 'white',
                                    },
                                } }), _jsx(Tab, { label: translate('paymentRecord.transactionRecord'), sx: {
                                    color: 'white',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    minHeight: isMobileScreen ? '48px' : '64px',
                                    fontSize: isMobileScreen ? '0.875rem' : '1rem',
                                    '&.Mui-selected': {
                                        color: 'white',
                                    },
                                } })] })) : acessControlList.dashboard_normal.view ? (_jsx(Tabs, { value: 1, sx: {
                            backgroundColor: "#1561a5",
                            borderRadius: '8px',
                            marginTop: '1.3rem',
                            width: '100%',
                            minHeight: isMobileScreen ? '48px' : '64px',
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'nowrap',
                            }
                        }, TabIndicatorProps: {
                            style: {
                                backgroundColor: '#03a9f4',
                                height: 6,
                                borderRadius: '1.5px',
                            },
                        }, children: _jsx(Tab, { label: "".concat(translate('pages.dashboard.title.yourOrganization')), sx: {
                                color: 'white',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                minHeight: isMobileScreen ? '48px' : '64px',
                                fontSize: isMobileScreen ? '0.875rem' : '1rem',
                                '&.Mui-selected': {
                                    color: 'white',
                                },
                            } }) })) : null }) }), _jsx(Grid, { item: true, xs: 12, sx: { mt: isMobileScreen ? 1 : 2, px: isMobileScreen ? 1 : 2 }, children: acessControlList.dashboard_admin.view ? (_jsxs(_Fragment, { children: [selectedTab === 0 && _jsx(AllOrganizations, {}), selectedTab === 1 && _jsx(YourDashboard, {}), selectedTab === 2 && _jsx(PaymentRecord, {})] })) : acessControlList.dashboard_normal.view ? (_jsx(YourDashboard, {})) : null })] }));
};
export default Dashboard;
