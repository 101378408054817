var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardActions, CircularProgress, Grid, Typography, } from '@mui/material';
import { required, TextInput, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
// import LoginLogo from '../assets/logos/login logo.png'
import ICSLogo from '../../assets/logos/ics logo.png';
// import '../../styles/globalStyle.css';
import '../../styles/global.module.css';
var ResetPW = function (_a) {
    var loading = _a.loading, notify = _a.notify, setSignInInputs = _a.setSignInInputs, signInInputs = _a.signInInputs, setCurComp = _a.setCurComp, handleResetPW = _a.handleResetPW, isMobileScreen = _a.isMobileScreen, isTabScreen = _a.isTabScreen;
    var translate = useTranslate();
    var cardWidth = "40%";
    switch (true) {
        case isMobileScreen:
            cardWidth = "75%";
            break;
        case isTabScreen:
            cardWidth = "60%";
            break;
    }
    return (_jsx(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background: '#ebeff2',
            backgroundSize: 'cover',
        }, children: _jsxs(Card, { sx: {
                //minWidth: 300,
                // maxWidth: 550,
                width: cardWidth,
                maxWidth: "27rem",
                marginTop: '6em',
                borderRadius: "40px",
                boxShadow: '20px 20px 25px rgba(77, 106, 149, 0.5)', // Light blue color
            }, children: [_jsx(Box, { sx: {
                        margin: '1em',
                        display: 'flex',
                        justifyContent: 'center',
                    }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 135, style: {
                            transition: 'transform 0.8s ease-in-out',
                        }, onMouseEnter: function (e) {
                            e.currentTarget.style.transform = 'scale(1.5)';
                        }, onMouseLeave: function (e) {
                            e.currentTarget.style.transform = 'scale(1)';
                        } }) }), _jsx(Box, { sx: {
                        marginTop: '1em',
                        display: 'flex',
                        flexWrap: "wrap",
                        justifyContent: 'center',
                        color: function (theme) { return theme.palette.grey[500]; },
                    }, children: _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", style: { backgroundColor: "", width: "30rem" }, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: 'big-text large-bold', textAlign: "center", color: "black", children: translate('login.AI_Screening_Platform') }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: 'normal-text normal-bold', textAlign: "center", color: "black", children: translate('login.ResetPassword') }) }), _jsx(Grid, { item: true, xs: 10, children: _jsx(Typography, { className: 'small-text no-bold', textAlign: "center", color: "black", sx: { marginTop: '1.5em' }, children: translate('login.resetPW.caption.inputPW') }) })] }) }), _jsxs(Box, { sx: { padding: '0 1em 1em 1em' }, children: [_jsxs(Box, { sx: { marginTop: '1em' }, children: [_jsx(Typography, { className: 'small-text large-bold', color: "#4d6a95", sx: { margin: '0px 0px 0px 10px' }, children: translate('login.YourPassword') }), _jsx(TextInput, { variant: 'outlined', autoFocus: true, source: "resetPW", type: "password", label: false, onChange: function (e) {
                                        setSignInInputs(__assign(__assign({}, signInInputs), { resetPW: e.target.value }));
                                    }, disabled: loading, validate: required(), fullWidth: true, sx: {
                                        '& .MuiInputBase-root': {
                                            borderRadius: '10px',
                                            height: "3rem",
                                            border: "1px solid #ccc",
                                        },
                                    } })] }), _jsxs(Box, { sx: { marginTop: '1em' }, children: [_jsx(Typography, { className: 'small-text large-bold', color: "#4d6a95", sx: { margin: '0px 0px 0px 10px' }, children: translate('login.ConfirmPassword') }), _jsx(TextInput, { variant: 'outlined', autoFocus: true, type: "password", source: "confirmResetPW", label: false, onChange: function (e) {
                                        setSignInInputs(__assign(__assign({}, signInInputs), { confirmResetPW: e.target.value }));
                                    }, disabled: loading, validate: required(), fullWidth: true, sx: {
                                        '& .MuiInputBase-root': {
                                            borderRadius: '10px',
                                            height: "3rem",
                                            border: "1px solid #ccc",
                                        },
                                    } })] })] }), _jsx(CardActions, { sx: { padding: '0 1em 1em 1em' }, children: _jsxs(Button, { className: 'normal-text login-button', variant: "contained", 
                        // type="submit"
                        color: "primary", disabled: loading, fullWidth: true, onClick: function () {
                            if (signInInputs.resetPW && signInInputs.confirmResetPW) {
                                handleResetPW();
                            }
                            else {
                                notify(translate('error.login.enterPW'), {
                                    type: 'warning',
                                });
                            }
                        }, children: [loading && (_jsx(CircularProgress, { size: 25, thickness: 2 })), translate('common.confirm')] }) }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", paddingY: 2, children: _jsx(Typography, { className: 'small-text large-bold', textAlign: "center", color: "#4d6a95", style: { cursor: "pointer" }, fontSize: "0.85em", sx: { margin: '0px 0px 70px 25px' }, onClick: function () {
                            setSignInInputs({ resetPW: "", confirmResetPW: "", email: "", token: "" });
                            setCurComp("signIn");
                        }, children: translate('login.BackToSignIn') }) })] }) }));
};
export default ResetPW;
