var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { dbDateHelper, subjectIdHelper, } from "../utils/helper";
import globalStyles from "../styles/globalStyles";
import { DatagridConfigurable, ExportButton, List, SelectColumnsButton, TopToolbar, WrapperField, FunctionField, Confirm, } from "react-admin";
import { useMediaQuery, Typography, Grid, IconButton, Button, Container, } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
var ListActions = function () { return (_jsxs(TopToolbar, { children: [_jsx(SelectColumnsButton, {}), _jsx(ExportButton, {})] })); };
var TableDatagrid = function (_a) {
    var setOrigDialogData = _a.setOrigDialogData, acessControlList = _a.acessControlList, translate = _a.translate, setSelectedRow = _a.setSelectedRow, data = _a.data, myOrg = _a.myOrg, setData = _a.setData, setSelectedColumn = _a.setSelectedColumn, selectedColumn = _a.selectedColumn, setDialogData = _a.setDialogData, dialogData = _a.dialogData, dialogOpened = _a.dialogOpened, setDialogDataAction = _a.setDialogDataAction, closeDialog = _a.closeDialog, educationLevelArray = _a.educationLevelArray, openEditDialog = _a.openEditDialog, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, sort = _a.sort, setOpenLeftPanel = _a.setOpenLeftPanel, filters = _a.filters, setFilters = _a.setFilters, getCurrentViewingUser = _a.getCurrentViewingUser, delAction = _a.delAction, _b = _a.tableWidth, tableWidth = _b === void 0 ? "500px" : _b, isMobileScreen = _a.isMobileScreen;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down("sm");
    });
    // const dispatch = useDispatch()
    var _c = useState(false), confirmOpen = _c[0], setConfirmOpen = _c[1];
    var _d = useState(null), recordToDelete = _d[0], setRecordToDelete = _d[1];
    var handleDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleConfirm = function () {
        if (recordToDelete) {
            delAction(recordToDelete);
        }
        setConfirmOpen(false);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    var handleListChange = function () {
    };
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var rowHeaderStyle = isTabScreen ? {
        display: "none"
    } : {};
    var postRowStyle = function (record, index) { return ({
        // backgroundColor: isTabScreen && index%2==0 ? 'WhiteSmoke' : 'white',
        border: '1px solid Gainsboro'
    }); };
    return (_jsxs(Container, { maxWidth: false, disableGutters: true, children: [_jsx(Grid, { container: true, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(List, { filterDefaultValues: filters, sort: { field: 'id', order: 'DESC' }, empty: false, debounce: handleListChange, actions: _jsx(ListActions, {}), children: _jsxs(DatagridConfigurable, { style: { tableLayout: "fixed" }, bulkActionButtons: false, rowClick: function (id, resource, record) {
                                setSelectedRow(record);
                                getCurrentViewingUser(record.id);
                            }, sx: {
                                '& .RaDatagrid-row': rowStyle,
                                '& .RaDatagrid-headerCell': rowHeaderStyle,
                                '& .RaDatagrid-rowCell': rowCellStyle,
                            }, rowStyle: postRowStyle, children: [_jsx(FunctionField, { source: "id", label: translate("pages.userList.fields.id"), render: function (record) {
                                        return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.id"), ":"] }), _jsx(Typography, { className: "small-text", children: subjectIdHelper(record.id) })] })
                                                : subjectIdHelper(record.id) }));
                                    } }), _jsx(FunctionField, { source: "organizations", label: translate("pages.userList.fields.organization"), render: function (record) {
                                        var _a, _b;
                                        return (_jsx(Typography, { className: "small-text", noWrap: true, children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.organization"), ":"] }), _jsx(Typography, { className: "small-text", children: (_a = record === null || record === void 0 ? void 0 : record.current_organization) === null || _a === void 0 ? void 0 : _a.orgName })] })
                                                : (_b = record === null || record === void 0 ? void 0 : record.current_organization) === null || _b === void 0 ? void 0 : _b.orgName }));
                                    } }), _jsx(FunctionField, { source: "userGroup", label: translate("pages.userList.fields.userGroup"), render: function (record) {
                                        var _a, _b;
                                        return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.userGroup"), ":"] }), _jsx(Typography, { className: "small-text", children: (_a = record.userGroups) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.name; }).join(', ') })] })
                                                : (_b = record.userGroups) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.name; }).join(', ') }));
                                    } }), _jsx(FunctionField, { source: "username", label: translate("pages.userList.fields.username"), render: function (record) {
                                        return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.username"), ":"] }), _jsx(Typography, { className: "small-text", children: record.username })] })
                                                : record.username }));
                                    } }), _jsx(FunctionField, { source: "Last_name", label: translate("pages.subject.fields.lastname"), render: function (record) {
                                        return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.lastname"), ":"] }), _jsx(Typography, { className: "small-text", children: record.Last_name })] })
                                                : record.Last_name }));
                                    } }), _jsx(FunctionField, { source: "First_name", label: translate("pages.subject.fields.firstname"), render: function (record) {
                                        return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.firstname"), ":"] }), _jsx(Typography, { className: "small-text", children: record.First_name })] })
                                                : record.First_name }));
                                    } }), _jsx(FunctionField, { source: "email", label: translate("pages.userList.fields.email"), render: function (record) {
                                        return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.email"), ":"] }), _jsx(Typography, { className: "small-text", children: record.email })] })
                                                : record.email }));
                                    } }), _jsx(FunctionField, { source: "gender", label: translate("pages.subject.fields.gender"), render: function (record) {
                                        var translatedGender = record.gender ? translate("params.subjects.".concat(record.gender)) : "";
                                        return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.gender"), ":"] }), _jsx(Typography, { className: "small-text", children: translatedGender })] })
                                                : translatedGender }));
                                    } }), _jsx(FunctionField, { source: "status", label: translate("pages.userList.fields.status"), render: function (record) {
                                        var bgColor;
                                        var hoverColor;
                                        var statusText = record.status ? translate("params.users.".concat(record.status)) : "";
                                        switch (true) {
                                            case (record.status == "Disabled" || record.status == "disabled"):
                                                bgColor = "red";
                                                hoverColor = "#FF6347"; // Tomato red
                                                break;
                                            case (record.status == "Not activate" || record.status == "Not Activate" || record.status == "Inactive"):
                                                bgColor = "#D58484";
                                                hoverColor = "#FF6666"; // Tomato red
                                                break;
                                            default:
                                                bgColor = "green";
                                                hoverColor = "#4CAF50"; // Lighter green
                                                break;
                                        }
                                        return (_jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [isTabScreen ?
                                                    _jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.status"), ":"] })
                                                    : _jsx(_Fragment, {}), _jsx(Button, { disabled: true, className: "small-text-button", sx: {
                                                        backgroundColor: bgColor,
                                                        // marginLeft: "-1rem",
                                                    }, children: statusText })] }));
                                    } }), _jsx(FunctionField, { source: "createdAt", label: translate("pages.userList.fields.createdAt"), render: function (record) {
                                        return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs(Grid, { style: globalStyles.centerContainer, justifyContent: isMobileScreen ? "space-between" : "start", children: [_jsxs(Typography, { width: !isMobileScreen ? '150px' : 'auto', style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.createdAt"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper(record.createdAt) })] })
                                                : dbDateHelper(record.createdAt) }));
                                    } }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { container: true, spacing: 0, alignItems: "center", children: [_jsx(FunctionField, { render: function (record) {
                                                    if (true) {
                                                        return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate("tooltip.viewUser"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () {
                                                                        setSelectedRow(__assign(__assign({}, record), { gender: translate("params.subjects.".concat(record.gender)) }));
                                                                        getCurrentViewingUser(record.id);
                                                                    }, children: _jsx(PreviewIcon, { fontSize: "small" }) }) }) }));
                                                    }
                                                    return null;
                                                } }), _jsx(FunctionField, { render: function (record) {
                                                    var _a;
                                                    var isMyOrg = ((_a = record.current_organization) === null || _a === void 0 ? void 0 : _a.orgName) === myOrg;
                                                    var canEdit = (acessControlList.user_admin.update ||
                                                        (acessControlList.user_normal.update && isMyOrg));
                                                    if (canEdit) {
                                                        return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate("tooltip.editUser"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () {
                                                                        openEditDialog(record);
                                                                    }, children: _jsx(EditIcon, { fontSize: "small" }) }) }) }));
                                                    }
                                                    return null;
                                                } }), _jsx(FunctionField, { render: function (record) {
                                                    var _a;
                                                    var isMyOrg = ((_a = record.current_organization) === null || _a === void 0 ? void 0 : _a.orgName) === myOrg;
                                                    var canDelete = (acessControlList.user_admin.delete ||
                                                        (acessControlList.user_normal.delete && isMyOrg));
                                                    if (canDelete) {
                                                        return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate("tooltip.deleteUser"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () { handleDelete(record); }, children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) }));
                                                    }
                                                    return null;
                                                } })] }) })] }) }) }) }), _jsx(Confirm, { isOpen: confirmOpen, title: translate("tooltip.deleteUser"), content: translate("comfirmDialog.user"), onConfirm: handleConfirm, onClose: handleCancel })] }));
};
export default TableDatagrid;
