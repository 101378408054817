var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { dbDateHelper, subjectIdHelper, } from '../../utils/helper';
import PaginationControlled from '../../utils/Pagination';
import { DatagridConfigurable, List, WrapperField, FunctionField, useDataProvider, useNotify, useRefresh, Confirm, Pagination, } from 'react-admin';
import { useMediaQuery, Typography, Grid, IconButton, Zoom, Box, Tooltip, } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterList from './GroupHelper/GroupFilterList';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import UserTransferList from './GroupHelper/UserTransferList';
import AcessRightDialog from './GroupHelper/AccessRightDialog';
import globalStyles from "../../styles/globalStyles";
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, setClickedPanelComp = _a.setClickedPanelComp, backgroundColor = _a.backgroundColor;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: {
            cursor: "pointer",
            backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white"
        }, onClick: function () { }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 0,
            },
        },
    }
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var GroupComp = function (_a) {
    var generalData = _a.generalData, dialogData = _a.dialogData, data = _a.data, allUserData = _a.allUserData, params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters, closeDialog = _a.closeDialog, opened = _a.opened, groupFilters = _a.groupFilters, resource = _a.resource, acessControlList = _a.acessControlList, reloadRecord = _a.reloadRecord, setDialogData = _a.setDialogData, searchFilterGroup = _a.searchFilterGroup, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, userData = _a.userData, accessDialogData = _a.accessDialogData, setAccessDialogData = _a.setAccessDialogData, handleDelGroup = _a.handleDelGroup, selectedViewOrgName = _a.selectedViewOrgName, handleSaveGroupUserList = _a.handleSaveGroupUserList, handleSaveGroupAcess = _a.handleSaveGroupAcess, paginationInfo = _a.paginationInfo, total = _a.total, setTotal = _a.setTotal, count = _a.count, setCount = _a.setCount, rowsPerPage = _a.rowsPerPage, setRowsPerPage = _a.setRowsPerPage, isMobileScreen = _a.isMobileScreen;
    var _b = useState({}), selectedGroupAccess = _b[0], setSelectedGroupAccess = _b[1];
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var _c = useState(false), openAccessDialog = _c[0], setOpenAccessDialog = _c[1];
    var _d = useState(false), openGroupDialog = _d[0], setOpenGroupDialog = _d[1];
    var _e = useState({}), groupDialogData = _e[0], setGroupDialogData = _e[1];
    //dialog box for confirm delete
    var _f = useState(false), confirmOpen = _f[0], setConfirmOpen = _f[1];
    var _g = useState(null), recordToDelete = _g[0], setRecordToDelete = _g[1];
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var rowHeaderStyle = isTabScreen ? {
        display: "none"
    } : {};
    var postRowStyle = function (record, index) { return ({
        // backgroundColor: isTabScreen && index%2==0 ? 'WhiteSmoke' : 'white',
        border: '1px solid Gainsboro'
    }); };
    var handleDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    var fetchAccessDetails = function (groupsId) {
        var groupParams = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "id", order: "ASC" },
            filter: {
                id: filters.id || "",
                name: filters.name || "",
                usersNum: filters.usersNum || ""
            }
        };
        setIsLoading(true);
        dataProvider.getListNoFilter("".concat(resource, "/").concat(generalData.id, "/groups/").concat(groupsId), groupParams)
            .then(function (rtnData) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if ((_b = (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.acl) {
                setAccessDialogData(__assign(__assign({}, accessDialogData), (_d = (_c = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) === null || _c === void 0 ? void 0 : _c.access) === null || _d === void 0 ? void 0 : _d.acl));
                // open the dialog
            }
            else {
                setAccessDialogData(__assign(__assign({}, accessDialogData), (_e = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) === null || _e === void 0 ? void 0 : _e.access));
            }
            console.log(__assign(__assign(__assign({}, accessDialogData), (_g = (_f = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) === null || _f === void 0 ? void 0 : _f.access) === null || _g === void 0 ? void 0 : _g.acl), (_h = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) === null || _h === void 0 ? void 0 : _h.access), '==groupAccess');
            setOpenAccessDialog(true);
            setIsLoading(false);
            refresh();
        }).catch(function (error) {
            // Handle any errors that occurred
            console.error(error, '==error');
            setIsLoading(false);
            notify(translate('error.common.getFailed'), {
                type: 'error',
            });
        });
    };
    var CustomPagination = function () { return (_jsx(Pagination, __assign({}, paginationInfo, { rowsPerPageOptions: [5, 10, 25] }))); };
    var handleTableClick = function (item) {
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "start", justifyContent: "start", width: "100%", backgroundColor: "", children: [_jsx(Box, { sx: { marginY: "1rem", paddingY: "1rem", backgroundColor: 'white', width: "100%", paddingX: "1rem", marginBottom: 0, paddingBottom: 0, }, children: _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters, searchFilterGroup: searchFilterGroup }) }), _jsx(Grid, { item: true, margin: "1rem", width: "100%", children: _jsx(List, { resource: "".concat(resource, "/").concat(generalData.id, "/groups"), empty: false, pagination: false, style: { width: "100%", backgroundColor: "", marginTop: "1rem", paddingY: "2rem" }, actions: "", children: _jsxs(DatagridConfigurable, { data: data, style: { tableLayout: "fixed" }, pagination: false, sx: {
                                    '& .RaDatagrid-row': rowStyle,
                                    '& .RaDatagrid-headerCell': rowHeaderStyle,
                                    '& .RaDatagrid-rowCell': rowCellStyle,
                                }, rowStyle: postRowStyle, bulkActionButtons: false, children: [_jsx(FunctionField, { source: "id", label: translate("pages.organizations.fields.id"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: {}, children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.id"), ":"] }), _jsx(Typography, { className: "small-text", children: subjectIdHelper(record.id) })] })
                                                    : subjectIdHelper(record.id) }));
                                        } }), _jsx(FunctionField, { source: "name", label: translate("pages.organizations.fields.groupName"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: {}, children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.groupName"), ":"] }), _jsx(Typography, { className: "small-text", children: record.name })] })
                                                    : record.name }));
                                        } }), _jsx(FunctionField, { source: "userNum", label: translate("pages.organizations.fields.userNum"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { style: {}, children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.userNum"), ":"] }), _jsx(Typography, { className: "small-text", children: record.usersNum })] })
                                                    : record.usersNum }));
                                        } }), _jsx(FunctionField, { source: "createAt", label: translate("pages.organizations.fields.createdAt"), render: function (record) {
                                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.createdAt"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper(record.createAt) })] })
                                                    : dbDateHelper(record.createAt) }));
                                        } }), _jsx(FunctionField, { source: "updatedAt", label: translate("pages.organizations.fields.updatedAt"), render: function (record) {
                                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.updatedAt"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper(record.createAt) })] })
                                                    : dbDateHelper(record.updatedAt) }));
                                        } }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), sortable: false, children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [(acessControlList.user_group_admin.update ||
                                                    (acessControlList.user_group_normal.update && selectedViewOrgName === localStorage.getItem('appBarOrg'))) &&
                                                    _jsx(FunctionField, { source: "", label: "", sortable: false, render: function (record) {
                                                            return (_jsx(Grid, { item: true, onClick: function () {
                                                                    setGroupDialogData(record);
                                                                    fetchAccessDetails(record === null || record === void 0 ? void 0 : record.id);
                                                                }, children: _jsx(Tooltip, { title: translate("tooltip.editAccessRight"), children: _jsx(IconButton, { size: "small", "aria-label": "close", style: { color: "#487ca8" }, children: _jsx(PreviewIcon, { fontSize: "small" }) }) }) }));
                                                        } }), (acessControlList.user_group_admin.update ||
                                                    (acessControlList.user_group_normal.update && selectedViewOrgName === localStorage.getItem('appBarOrg'))) &&
                                                    _jsx(FunctionField, { source: "", label: "", sortable: false, render: function (record) {
                                                            return (_jsx(Grid, { item: true, onClick: function () {
                                                                    setGroupDialogData(record);
                                                                    setOpenGroupDialog(true);
                                                                }, children: _jsx(Tooltip, { title: translate("tooltip.editGroup"), children: _jsx(IconButton, { size: "small", "aria-label": "close", style: { color: "#487ca8" }, children: _jsx(PeopleAltIcon, { fontSize: "small" }) }) }) }));
                                                        } }), (acessControlList.user_group_admin.delete ||
                                                    (acessControlList.user_group_normal.delete && selectedViewOrgName === localStorage.getItem('appBarOrg'))) &&
                                                    _jsx(FunctionField, { source: "", label: "", sortable: false, render: function (record) {
                                                            return (_jsx(Grid, { item: true, onClick: function () {
                                                                    handleDelete(record.id);
                                                                }, children: _jsx(Tooltip, { title: translate("tooltip.deleteGroup"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) }));
                                                        } })] }) })] }) }) }), openGroupDialog && _jsx(UserTransferList, { translate: translate, dialogData: groupDialogData, allUserData: allUserData, opened: openGroupDialog, selectedViewOrgName: selectedViewOrgName, closeDialog: function () { setOpenGroupDialog(false); }, handleSave: handleSaveGroupUserList }), (openAccessDialog && !isLoading) && _jsx(AcessRightDialog, { translate: translate, dialogData: groupDialogData, setDialogData: setGroupDialogData, allUserData: allUserData, opened: openAccessDialog, selectedViewOrgName: selectedViewOrgName, closeDialog: function () { setOpenAccessDialog(false); }, handleSave: function () { handleSaveGroupAcess(accessDialogData, groupDialogData.id, groupDialogData.name); setOpenAccessDialog(false); }, access: accessDialogData, setAccess: setAccessDialogData }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", justifyContent: "end", children: _jsx(PaginationControlled, { searchFilterGroup: searchFilterGroup, total: total, setTotal: setTotal, count: count, setCount: setCount, rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage }) })] }), _jsx(Confirm, { isOpen: confirmOpen, title: translate("tooltip.deleteGroup"), content: translate("comfirmDialog.group"), onConfirm: function () { handleDelGroup(recordToDelete); setConfirmOpen(false); }, onClose: handleCancel })] }));
};
export default GroupComp;
