var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import { useState } from "react";
import { Grid, Typography, Dialog, DialogActions, DialogContent, Zoom } from '@mui/material';
import { Formik } from 'formik';
import { dialog } from '../utils/componentWizard';
import { useNotify, } from 'react-admin';
import { getTodaysDateChinaTimeZone } from '../utils/datePickerTimeFormat';
import popupStyles from "../styles/popup/popup.module.css";
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 0,
            },
        },
    }
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var ExportDialog = function (_a) {
    var translate = _a.translate, opened = _a.opened, data = _a.data, 
    // openDialog,
    closeDialog = _a.closeDialog, reloadRecord = _a.reloadRecord;
    var notify = useNotify();
    var _b = useState(getTodaysDateChinaTimeZone()), exam_date = _b[0], set_exam_date = _b[1];
    var _c = useState("submit"), submitType = _c[0], setSubmitType = _c[1];
    var exportNameHelper = function (strArr) {
        var updatedStr = strArr.join(', ');
        if (updatedStr.includes('fpAndoct')) {
            updatedStr = updatedStr.replace('fpAndoct', translate("pages.dataExport.fields.fp_oct"));
        }
        if (updatedStr.includes('fp')) {
            updatedStr = updatedStr.replace('fp', translate("pages.dataExport.fields.fp") + 's');
        }
        if (updatedStr.includes('oct')) {
            updatedStr = updatedStr.replace('oct', translate("pages.dataExport.fields.oct"));
        }
        // if (updatedStr.includes('octReports')) {
        //     updatedStr = updatedStr.replace('octReports', translate(`pages.dataExport.fields.oct_reports`));
        // }
        return updatedStr;
    };
    return (_jsx(React.Fragment, { children: _jsxs(Dialog, { open: opened, TransitionComponent: Transition, onClose: closeDialog, className: classes.noMarginDialog, keepMounted: true, children: [_jsx(dialog.header, { title: translate("pages.dataExport.name"), handleClose: closeDialog }), _jsx(Formik, { enableReinitialize: true, initialValues: data, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            try {
                                reloadRecord(__assign(__assign({}, values), { exam_date: new Date(exam_date) }));
                            }
                            catch (err) {
                                console.log('==err', err);
                                // dispatch(actionTypes.SNACKBAR_ERROR);
                            }
                            return [2 /*return*/];
                        });
                    }); }, children: function (props) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
                        var values = props.values, handleSubmit = props.handleSubmit;
                        return (_jsxs("form", { noValidate: true, onSubmit: handleSubmit, children: [_jsx(DialogContent, { className: popupStyles.dialogContent, children: _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", style: { backgroundColor: "" }, children: [_jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: [_jsx(Typography, { variant: "body1", padding: 2, children: translate("pages.dataExport.dialog.subjects_selected") }), _jsx(Typography, { fontWeight: "bold", variant: "body1", padding: 2, children: data === null || data === void 0 ? void 0 : data.selected })] }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsx(Typography, { variant: "body1", padding: 2, children: translate("pages.dataExport.name") + ":" }) }), ((_a = data === null || data === void 0 ? void 0 : data.dataExport) === null || _a === void 0 ? void 0 : _a.subjectInformation) &&
                                                _jsx(Grid, { item: true, backgroundColor: "", paddingLeft: 2, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsx(Typography, { fontWeight: "bold", variant: "body1", paddingX: 2, children: "•" + "   " + translate("pages.dataExport.fields.subject_information") }) }), (((_c = (_b = data === null || data === void 0 ? void 0 : data.dataExport) === null || _b === void 0 ? void 0 : _b.images) === null || _c === void 0 ? void 0 : _c.fp) || ((_e = (_d = data === null || data === void 0 ? void 0 : data.dataExport) === null || _d === void 0 ? void 0 : _d.images) === null || _e === void 0 ? void 0 : _e.oct)) &&
                                                _jsx(Grid, { item: true, backgroundColor: "", paddingLeft: 2, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsx(Typography, { fontWeight: "bold", variant: "body1", paddingX: 2, children: "•" + "   " + translate("pages.dataExport.fields.images") + "(".concat(exportNameHelper(Object.keys((_f = data === null || data === void 0 ? void 0 : data.dataExport) === null || _f === void 0 ? void 0 : _f.images).filter(function (key) { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.dataExport) === null || _a === void 0 ? void 0 : _a.images[key]) === true; })), ")") }) }), (((_h = (_g = data === null || data === void 0 ? void 0 : data.dataExport) === null || _g === void 0 ? void 0 : _g.retinADReports) === null || _h === void 0 ? void 0 : _h.fp) || ((_k = (_j = data === null || data === void 0 ? void 0 : data.dataExport) === null || _j === void 0 ? void 0 : _j.retinADReports) === null || _k === void 0 ? void 0 : _k.oct) || ((_m = (_l = data === null || data === void 0 ? void 0 : data.dataExport) === null || _l === void 0 ? void 0 : _l.retinADReports) === null || _m === void 0 ? void 0 : _m.fpAndoct)) &&
                                                _jsx(Grid, { item: true, backgroundColor: "", paddingLeft: 2, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsx(Typography, { fontWeight: "bold", variant: "body1", paddingX: 2, children: "•" + "   " + translate("pages.dataExport.fields.retinAD_reports") + "(".concat(exportNameHelper(Object.keys((_o = data === null || data === void 0 ? void 0 : data.dataExport) === null || _o === void 0 ? void 0 : _o.retinADReports).filter(function (key) { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.dataExport) === null || _a === void 0 ? void 0 : _a.retinADReports[key]) === true; })), ")") }) }), (((_q = (_p = data === null || data === void 0 ? void 0 : data.dataExport) === null || _p === void 0 ? void 0 : _p.retinADProbabilityScores) === null || _q === void 0 ? void 0 : _q.fp) || ((_s = (_r = data === null || data === void 0 ? void 0 : data.dataExport) === null || _r === void 0 ? void 0 : _r.retinADProbabilityScores) === null || _s === void 0 ? void 0 : _s.oct) || ((_u = (_t = data === null || data === void 0 ? void 0 : data.dataExport) === null || _t === void 0 ? void 0 : _t.retinADProbabilityScores) === null || _u === void 0 ? void 0 : _u.fpAndoct)) &&
                                                _jsx(Grid, { item: true, backgroundColor: "", paddingLeft: 2, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsx(Typography, { fontWeight: "bold", variant: "body1", paddingX: 2, children: "•" + "   " + translate("pages.dataExport.fields.retinAD_probability_scores") + "(".concat(exportNameHelper(Object.keys((_v = data === null || data === void 0 ? void 0 : data.dataExport) === null || _v === void 0 ? void 0 : _v.retinADProbabilityScores).filter(function (key) { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.dataExport) === null || _a === void 0 ? void 0 : _a.retinADProbabilityScores[key]) === true; })), ")") }) }), ((_w = data === null || data === void 0 ? void 0 : data.dataExport) === null || _w === void 0 ? void 0 : _w.questionnaireResults) &&
                                                _jsx(Grid, { item: true, backgroundColor: "", paddingLeft: 2, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsx(Typography, { fontWeight: "bold", variant: "body1", paddingX: 2, children: "•" + "   " + translate("pages.dataExport.fields.questionnaire_results") }) }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsx(Typography, { variant: "body1", padding: 2, children: translate("pages.dataExport.criteria") + ":" }) }), ((_x = data === null || data === void 0 ? void 0 : data.dataExport) === null || _x === void 0 ? void 0 : _x.examDate) &&
                                                _jsx(Grid, { item: true, backgroundColor: "", paddingLeft: 2, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsxs(Typography, { fontWeight: "bold", variant: "body1", paddingX: 2, children: ["•" + "   " + translate("pages.dataExport.fields.examDate") + " date", " " + "(".concat((_z = (_y = data.criteria) === null || _y === void 0 ? void 0 : _y.examFromDate) !== null && _z !== void 0 ? _z : "N/A", " - ").concat((_1 = (_0 = data.criteria) === null || _0 === void 0 ? void 0 : _0.examToDate) !== null && _1 !== void 0 ? _1 : "N/A", ")")] }) }), ((_2 = data === null || data === void 0 ? void 0 : data.dataExport) === null || _2 === void 0 ? void 0 : _2.analyzeDate) &&
                                                _jsx(Grid, { item: true, backgroundColor: "", paddingLeft: 2, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsxs(Typography, { fontWeight: "bold", variant: "body1", paddingX: 2, children: ["•" + "   " + translate("pages.dataExport.fields.analyzeDate") + " date", " " + "(".concat((_4 = (_3 = data.criteria) === null || _3 === void 0 ? void 0 : _3.analyzeFromDate) !== null && _4 !== void 0 ? _4 : "N/A", " - ").concat((_6 = (_5 = data.criteria) === null || _5 === void 0 ? void 0 : _5.analyzeToDate) !== null && _6 !== void 0 ? _6 : "N/A", ")")] }) }), !((_7 = data === null || data === void 0 ? void 0 : data.dataExport) === null || _7 === void 0 ? void 0 : _7.examDate) && !((_8 = data === null || data === void 0 ? void 0 : data.dataExport) === null || _8 === void 0 ? void 0 : _8.analyzeDate) &&
                                                _jsx(Grid, { item: true, backgroundColor: "", paddingLeft: 2, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", children: _jsx(Typography, { fontWeight: "bold", variant: "body1", paddingX: 2, children: "\u2022   N/A" }) })] }) }), _jsx(DialogActions, { children: _jsx(Grid, { item: true, xs: 12, marginY: 1, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", onClick: function () { setSubmitType("submit"); }, children: _jsx(dialog.submitButton, { isSubmitting: props.isSubmitting, text: translate("dialog.export") }) }) })] }));
                    } })] }) }));
};
export default ExportDialog;
